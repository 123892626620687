.about-me-left{
  padding:0rem;
  height: auto;
}

.skillset-section{
  height: 100vh;
}

.about-me-right{
  background-color: $tan;
  height: 100vh !important;
}

.skill-title{
  height: 6rem;
  font-size: 4rem;
  background-color: $green;
  font-family: $headings;
  align-items: center;
  font-weight: 100;
}

.contact-container{
  width: 100vw;
  height:20rem;
  background-color: $green;
  text-align: start;
}

.contact-heading{
  color: white;
  font-size: 5rem;
  font-weight: 100;
  font-weight: 100;
}

.contact-text{
  font-size: 1rem;
  font-family: $standard;
  color: white;
  text-decoration: none;
  font-weight: 100;
  letter-spacing: 2px;
  line-height: 1.5rem;

  &:hover{
    color: white;
  }
}

.single-skill{
  width: 11.25rem;
  height: 3rem;
}

.skill-circle{
  height: 1rem;
  width: 1rem;
  background-color: $green;
  border-radius: 100%;
  margin:1px;

  &.outer{
    border:1px solid $green;
    background-color: white;
  }
}

.skill-name{
  font-family: $standard;
  font-weight: 100;
  font-size: 1.25rem;
  letter-spacing: 2px;
  margin-bottom: .25rem;
  width: 13rem;
}