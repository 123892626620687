@import './styles/Intro.scss';
@import './styles/About.scss';
@import './styles/Experience.scss';
@import './styles/Skillset.scss';
@import './styles/Navigation.scss';


@media only screen and (max-width: 768px) {
  .app-option {
    height: 6.5rem;
    width: 6.5rem;
  }

  .app-intro-container {
    min-height: 36rem;
  }

  .about-right{
    height: 28rem;
  }

  .skillset-section{
    height: 28rem;
  }

  .experience-section-title{
    height: 4rem;
    font-size: 2.5rem;
  }
  
  .skill-title{
    height: 4rem;
    font-size: 2.5rem;
  }

  .name-heading {
    font-size: 2.5rem;
  }

  .contact-heading {
    font-size: 2.5rem;
  }

  .role-heading{
    font-size: 1.25rem;
  }

  .about-container{
    flex-direction: column;

    &.experience{
      flex-direction: column-reverse;
    }
  }

  .sticky{
    position: relative;
    height: 20rem !important;
  }

  .headings{
    font-size: 2.5rem;
  }

  .MuiTypography-body1{
    font-size: .75rem !important;
  }

  .experience-section-heading{
    font-size: 1rem;
  }

  .experience-title{
    font-size: 1rem;
    margin-top: -.8rem;
  }

  .contact-container {
    height: 25rem;
  }

  .navigation-bar {
    left: 1rem;
  }

  .phoebe-home-image{
    width: 20rem;
    padding-top: 20rem;
  }
}